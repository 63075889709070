.PageNotFoundContainer{
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    width: 600px;
}

.Button{
    padding-top: 2%;
}

.Text{
    font-size: 22px;
}

.message-header{
    justify-content: center;
}