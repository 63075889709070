.DashboardPage{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 100%
}

.DashboardContent, .EmbeddedDashboard{
    width: 100%;
    height: 100%
}

.DefaultDashboard{
    filter: blur(2px);
}

.DashboardHeader{
    width: 100%;
    flex-grow: 1; 
    padding: 15px 15px 0px 15px
}

.DashboardFooter{
    width: 100%;
    flex-grow: 1; 
    padding-bottom: 15px;
}

.Snapshot{
    width: 100%;
    padding: 0px 0px 20px 15px
}

.SubmitButton{
   padding-left: 15px
}

.DropdownField{
    width: 500px;
    padding-left: 15px
}

.confluence-icon, .logout-icon{
    font-size: 20px;
    padding-left: 15px;
}

.general-info-icon{
    font-size: 20px;
    color: #485FC7;
    padding-right: 15px
}

#link {
    color: black
}

.SnapshotError{
    display: flex;
    flex-direction: column;
    font-size: 20px;
    padding: 10px 10px 10px 10px
}

.DropdownBodyContent{
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
}

.DropdownBody{
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    width: 67%
}

.Refresh{
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    padding-right: 5%;
    width: 33%
}