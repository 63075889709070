.LoginContainer{
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 500px;
}

.LoginButtonContainer{
    padding-top: 20px;
    align-self: flex-end;
}

.LoginContent{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 500px;
    flex-grow: 10;
}

.LoginLabels{
    display: flex;
}

.LoginPage{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 100%
}

.LoginHeader{
    padding-top: 15px;
}

.LoginFooter, .LoginHeader{
    width: 100%;
    flex-grow: 1
}

.LoginError{
    display: flex;
    flex-direction: column;
    font-size: 20px;
    padding: 10px 0px 10px 0px
}
